import { VIDEO_MIME_TYPES } from 'helper/constants';
import './AssetListItem.scss';
import {
  convertUTCtoLocal,
} from 'helper/utils';
import { NotificationManager } from 'react-notifications';
import { UserName } from 'components/UserName';

const AssetListItem = props => {
  const { asset } = props;

  if (!asset) {
    return <></>;
  }

  const {
    quantity,
    primary_user_id,
    updated_at,
    id,
    created_at,
    results,
    mime_type,
    rating,
  } = asset;

  const onCopyURL = (url) => {
    navigator.clipboard.writeText(url);
    NotificationManager.success(
      'The URL has been copied to clipboard',
      'Succes',
      2000
    );
  }

  return (
    <tr key={id} className='asset-list-item'>
      <td className='thumbnails'>
        {VIDEO_MIME_TYPES.includes(mime_type) ? (
          results.map(result => (
            <video width="80px" controls key={result} className='item' onClick={() => onCopyURL(result)}>
              <source src={result} type={mime_type} />
            </video>
          ))
        ) : results.map(result => (
          <img alt={result} width="80px" src={result} key={result} className='item' onClick={() => onCopyURL(result)} />
        ))}
      </td>
      <td className='id'>
        {id}
      </td>
      <td className='username'>
        <UserName primary_user_id={primary_user_id} />
      </td>
      <td className='created-at'>
        <span>{convertUTCtoLocal(created_at).format('yyyy-MM-DD HH:mm')}</span>
      </td>
      <td className='rating'>
        <span className='text-center'>{rating || ""}</span>
      </td>
    </tr>
  );
};

export { AssetListItem };
