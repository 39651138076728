import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Alert } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import './AIStatistics.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getAIStatistics } from 'redux-layer/actions';
import { DetailViewItem } from 'components/DetailItemView';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

const labels = {
  'count.NEW': 'New',
  'count.REQUESTED': 'Requested',
  'count.FINISHED': 'Finished',
};

const CustomLegend = props => {
  const { payload } = props;
  return (
    <div className='custom-legend'>
      {payload.map(item => (
        <div className='legend-item' style={{ color: item.color }}>
          <div className='color-box' style={{ background: item.color }} />
          {labels[item.payload.dataKey]}
        </div>
      ))}
    </div>
  );
};

const CustomTooltip = ({ active, payload, label, showDay = false }) => {
  if (active && payload && payload.length) {
    let dateString = '';
    if (payload[0] && payload[0].payload && payload[0].payload.day) {
      dateString = moment(payload[0].payload.day).format('MM/DD/YYYY');
    }
    return (
      <div className='custom-tooltip'>
        {payload.map(item => (
          <p className='label'>
            {`${labels[item['dataKey']] || item['dataKey']}`}:{' '}
            <strong>{item.value}</strong>
          </p>
        ))}
        {showDay && dateString && <p className='label'>Date: {dateString}</p>}
      </div>
    );
  }

  return null;
};

const AIStatistics = props => {
  const [dateRange, setDateRange] = useState([
    {
      startDate: moment().subtract(2, 'days').toDate(),
      endDate: moment().subtract(1, 'days').toDate(),
      key: 'selection'
    }
  ]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const startDate = moment(dateRange[0].startDate);
    let endDate = moment(dateRange[0].endDate);

    if (endDate.isBefore(startDate)) {
      endDate = startDate
    }

    const startDateString = startDate.format('YYYY-MM-DD');
    const endDateString = endDate.format('YYYY-MM-DD');

    dispatch(
      getAIStatistics(startDateString, endDateString,)
    );
  }, [dateRange, dispatch]);

  const handleSelect = ranges => {
    if (ranges && ranges.selection) {
      setDateRange([ranges.selection]);
    }
  };

  const { aiUsageStatistics, isLoadingStatistics } = useSelector(state => ({
    aiUsageStatistics: state.statistic.aiUsageStatistics,
    isLoadingStatistics: state.statistic.isLoadingStatistics
  }));

  // const aiUsageStatistics = {"LeonardoMotion":{"count":{"NEW":0,"REQUESTED":10,"FINISHED":891,"NONE":0},"total_duration":278948.8221830003,"sum_processing":68163.74622099995},"StableVideoDiffusion":{"count":{"NEW":0,"REQUESTED":0,"FINISHED":0,"NONE":0},"total_duration":0,"sum_processing":0},"DynamiCrafter":{"count":{"NEW":42,"REQUESTED":38,"FINISHED":1633,"NONE":0},"total_duration":432295.7007210001,"sum_processing":155910.2925980001},"HaiperVideo":{"count":{"NEW":3,"REQUESTED":288,"FINISHED":2967,"NONE":0},"total_duration":1501841.2561809982,"sum_processing":1009112.9270989991},"LumaAI":{"count":{"NEW":0,"REQUESTED":3,"FINISHED":855,"NONE":0},"total_duration":100214.53159599996,"sum_processing":36591.83478099996},"RunwayML":{"count":{"NEW":0,"REQUESTED":0,"FINISHED":167,"NONE":0},"total_duration":71049.22170899995,"sum_processing":3572.2263310000003},"KlingAI":{"count":{"NEW":0,"REQUESTED":8,"FINISHED":93,"NONE":0},"total_duration":306495.46000500006,"sum_processing":31882.220535}}
  // const isLoadingStatistics = false;

  const aiUsages = Object.keys(aiUsageStatistics).map(motion_key => {
    const ai_usage = { ...aiUsageStatistics[motion_key] }
    ai_usage['motion_type'] = motion_key
    return ai_usage;
  });

  return (
    <div className='user-statistics-page'>
      <div className='container-fluid'>
        <div className='page-header'>
          <h1>
            Statistics - Third Party AI Usage
            {isLoadingStatistics && <i className='fas fa-redo fa-spin' />}
          </h1>
          <Button
            variant='primary'
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </div>
        <Alert>
          All statistical data are available starting from Nov 2024.
        </Alert>
        <div className='row'>
          <div className='col-lg-6 mb-3'>
            <div className='date-range-picker-wrapper'>
              <DateRangePicker ranges={dateRange} onChange={handleSelect} />
            </div>
          </div>
          {/* <div className='col-lg-6 mb-3'>
            <div className='row'>
              <div className='col-md-2'>
                <DetailViewItem title='Submitted' value={totalSubmissions} />
              </div>
              <div className='col-md-2'>
                <DetailViewItem
                  title='Video Generated'
                  value={totalGenerated}
                />
              </div>
              <div className='col-md-2'>
                <DetailViewItem title='Image Generated' value={imageGeneated} />
              </div>
              <div className='col-md-2'>
                <DetailViewItem title='Customer Credits' value={totalCredits - vimmerseUsed} />
              </div>
              <div className='col-md-2'>
                <DetailViewItem title='Vimmerse Credits' value={vimmerseUsed} />
              </div>
              <div className='col-md-2'>
                <DetailViewItem title='Total Credits' value={totalCredits} />
              </div>
            </div>
            <div className='row'>
              <div className='col-md-2'>
                <DetailViewItem title='Unique Users' value={Object.values(monthUserCreated).length} />
              </div>
            </div>
          </div> */}
        </div>
        <div className='row'>
          <div className='col-lg-12 mb-3 mt-3'>
            <h5>AI Tool Usages by Motion Type</h5>
            <div className='chart-wrapper'>
              <ResponsiveContainer width='100%' height='100%'>
                <BarChart width={800} height={300} data={aiUsages}>
                  <Bar stackId="a" dataKey='count.NEW' fill='#1205fa' />
                  <Bar stackId="a" dataKey='count.REQUESTED' fill='#f71acc' />
                  <Bar stackId="a" dataKey='count.FINISHED' fill='#1ddb12' />
                  <CartesianGrid stroke='#ccc' strokeDasharray='3 3' />
                  <XAxis dataKey='motion_type' />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend content={<CustomLegend />} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export { AIStatistics };
