export const startReqActionType = str => `${str}_REQUEST`;
export const failReqActionType = str => `${str}_FAILURE`;
export const successReqActionType = str => `${str}_SUCCESS`;
export const networkAuthRequired = str => `${str}_NETWORK_AUTH_REQUIRED`;
export const noContentReqActionType = str => `${str}_NO_CONTENT`;
export const notAuthorizedReqActionType = str => `${str}_NOT_AUTHORIZED`;
export const unhandledReqActionType = str => `${str}_UNHANDLED_REQUEST`;

export const apiHeader = () => {
  const customerString = localStorage.getItem('customer');
  if (customerString) {
    const customerValue = JSON.parse(customerString);
    return {
      'Content-Type': 'application/json',
      'request-origin': 'admin',
      Authorization: localStorage.getItem('@accessToken'),
      'X-CLIENT-TYPE': 'Vimmerse Admin Website',
      'X-Customer-Id': customerValue.customer_id,
      'X-Api-Key': customerValue.customer_key
    };
  }
};

export const authHeader = () => {
  return {
    'Content-Type': 'application/json',
    'request-origin': 'admin',
    Authorization: localStorage.getItem('@accessToken'),
    'X-CLIENT-TYPE': 'Vimmerse Admin Website',
    'X-Api-Key': process.env.REACT_APP_DEFAULT_API_KEY
  };
};

export const processResponse = async resp => {
  const json = await resp.json();
  if (resp.ok) {
    return json.data;
  } else {
    throw new Error(json.message);
  }
};
