import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  apiHeader,
  processResponse
} from 'helper/requestHelper';
import { getEnvironment } from 'helper/utils';
import { NotificationManager } from 'react-notifications';

export const paginatedListAssets = (params, reset) => async dispatch => {
    dispatch({ type: startReqActionType(actionConstants.PAGINATED_LIST_ASSETS) });
    const requestOptions = {
      method: 'GET',
      headers: apiHeader()
    };
  
    try {
      const response = await fetch(
        `${getEnvironment().API_URL}assets?${params}`,
        requestOptions
      );
      const data = await processResponse(response);
      dispatch({
        type: successReqActionType(actionConstants.PAGINATED_LIST_ASSETS),
        payload: { ...data, reset }
      });
    } catch (error) {
      dispatch({
        type: failReqActionType(actionConstants.PAGINATED_LIST_ASSETS)
      });
    }
  };