/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Table,
  Alert
} from 'react-bootstrap';
import './AssetsList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { paginatedListAssets } from 'redux-layer/actions';
import { VimmersePaginationBar } from 'components/VimmersePaginationBar';
import { CustomerSelect } from 'components/CustomerSelect';
import { AssetListItem } from 'components/AssetListItem/AssetListItem';

const AssetsList = props => {
  const queryParam = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    paginatedAssets,
    lastEvaluatedKeys,
    totalCount,
    isLoadingAssetsList
  } = useSelector(state => ({
    paginatedAssets: state.assets.paginatedAssets,
    lastEvaluatedKeys: state.assets.lastEvaluatedKeys,
    totalCount: state.assets.totalCount,
    isLoadingAssetsList: state.assets.isLoadingAssetsList
  }));

  // const [ratingFilter, setRatingFilter] = useState([]);
  const [createdByFilter, setCreatedByFilter] = useState(
    queryParam.get('username') || ''
  );

  const [perPage, setPerPage] = useState(40);
  const [page, setPage] = useState(1);
  const [customer, setCustomer] = useState(null);

  const searchParams = useSearchParams();

  useEffect(() => {
    setCreatedByFilter(searchParams[0].get('created_by', ''));

    // const ratingValue = searchParams[0].get('rating') || '';
    // const rataingValues = ratingValue.split(',');
    // const selectedRatings = RATING_OPTIONS.filter(ro =>
    //   rataingValues.includes(ro.value)
    // );
    // setRatingFilter(selectedRatings);

    const queryString = searchParams[0].toString();
    onStartSearch(queryString, true);
  }, []);

  const onStartSearch = (queryString, reset = false) => {
    dispatch(paginatedListAssets(queryString, reset));
  };

  const onSearch = (reset = false) => {
    if (reset) {
      setPage(1);
    }

    const params = new URLSearchParams({
      created_by: createdByFilter || '',
      // rating: ratingFilter.map(obj => obj.value).join(','),
      last_evaluated_key: reset ? '' : JSON.stringify(lastEvaluatedKeys)
    });
    const queryString = params.toString();
    onStartSearch(queryString, reset);
    navigate(`/assets?${queryString}`);
  };

  const onChangeRowsPerPage = newPerPage => {
    setPerPage(newPerPage);
    setPage(1);
  };

  const onChangePage = page => {
    const required_count = page * perPage;
    if (required_count > paginatedAssets.length) {
      if (lastEvaluatedKeys) {
        onSearch();
      } else {
        return;
      }
    }

    setPage(page);
  };

  let totalPages = 0;

  if (!lastEvaluatedKeys) {
    if (paginatedAssets.length) {
      totalPages = Math.ceil(totalCount / perPage);
    }
  }

  const displayingAssets = paginatedAssets.slice(
    (page - 1) * perPage,
    page * perPage
  );

  return (
    <div className='sessions-list-page'>
      <div className='page-header'>
        <h1>Assets List</h1>
        <div></div>
      </div>
      <div className='contents'>
        <div className='row align-items-end pb-2'>
          <div className='col-md-2'>
            <FormGroup>
              <FormLabel>Customer</FormLabel>
              <CustomerSelect
                onChangeCustomer={setCustomer}
                customer={customer}
              />
            </FormGroup>
          </div>
          <div className='col-md-2'>
            <FormGroup>
              <FormLabel>Created By</FormLabel>
              <FormControl
                type='text'
                value={createdByFilter}
                placeholder='Please input creator to search'
                onChange={e => setCreatedByFilter(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-2'>
            <Button onClick={() => onSearch(true)}>Search</Button>
          </div>
        </div>
        <div className='mt-2'>
          <VimmersePaginationBar
            onChangePage={onChangePage}
            currentPage={page}
            isLastPage={!lastEvaluatedKeys && totalPages === page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPage={perPage}
          />
          {isLoadingAssetsList && (
            <Alert variant='info'>Loading Assets...</Alert>
          )}
          <Table striped responsive>
            <thead>
              <td>Thumbnail</td>
              <td>ID</td>
              <td>Username</td>
              <td>Created At</td>
              {/* <td>Updated At</td> */}
              <td>Rating</td>
            </thead>
            <tbody>
              {displayingAssets.map(pm => (
                <AssetListItem asset={pm} key={pm.id} />
              ))}
            </tbody>
          </Table>
          <VimmersePaginationBar
            onChangePage={onChangePage}
            currentPage={page}
            isLastPage={!lastEvaluatedKeys && totalPages === page}
            onChangeRowsPerPage={onChangeRowsPerPage}
            rowsPerPage={perPage}
          />
        </div>
      </div>
    </div>
  );
};

export { AssetsList };
