import { useSelector } from 'react-redux';
import './AIToolUsageMeter.scss';
import { groupBy } from 'lodash';
import { AI_TOOL_STATUS_FAILED, AI_TOOL_STATUS_FINISHED, AI_TOOL_STATUS_NEW, AI_TOOL_STATUS_REQUESTED } from 'helper/constants';

const AIToolUsageMeter = props => {
  const { recentAIUsages } = useSelector(state => ({
		recentAIUsages: state.statistic.recentAIUsages
	}));
	
	const usagesByMotionType = groupBy(recentAIUsages, 'motion_type');
	return (
		<div className='row ai-tool-usage-meter'>
			{Object.keys(usagesByMotionType).map(serviceName => {
				const serviceHistory = usagesByMotionType[serviceName];
				let newsCount = 0, runningCount = 0, finishedCount = 0, failedCount = 0;
				let processingTimes = 0, durations = 0;
				serviceHistory.forEach(historyItem => {
					if (historyItem.status === AI_TOOL_STATUS_NEW) {
						newsCount++;
					} else if (historyItem.status === AI_TOOL_STATUS_REQUESTED) {
						runningCount++;
					} else if (historyItem.status === AI_TOOL_STATUS_FINISHED) {
						finishedCount++;
						durations += Number(historyItem.duration);
						processingTimes += Number(historyItem.processing_time);
					} else if (historyItem.status === AI_TOOL_STATUS_FAILED) {
						failedCount++;
					}
				});
				return (
					<div className='col-6 col-md-4 col-lg-2'>
						<div className={`service-item ${serviceName}`}>
							<div className='service-name'>
								{serviceName}
							</div>
							<div className='running'>
								<h3>{runningCount}</h3>
								<p>Running</p>
							</div>
							<div className='other'>
								<div className='new'>
									<h3>{newsCount}</h3>
									<p>Requesting</p>
								</div>
								<div className='finished'>
									<h3>{finishedCount}</h3>
									<p>Finished</p>
								</div>
								<div className='failed'>
									<h3 className='text-danger'>{failedCount}</h3>
									<p>Failed</p>
								</div>
							</div>
							<div className='other'>
								<div className='avg-total'>
									<h3>{(durations / finishedCount).toFixed(2)}</h3>
									<p>Avg Total Time</p>
								</div>
								<div className='avg-processing'>
									<h3>{(processingTimes / finishedCount).toFixed(2)}</h3>
									<p>Avg Processing Time</p>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export { AIToolUsageMeter };