import { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { BrowserRouter as Router, Routes } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession, signOut } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import { Container } from 'react-bootstrap';
import './App.scss';
import { MediaList } from './containers/MediaList';
import { MediaDetail } from 'containers/MediaDetail';
import { UsersList } from 'containers/UsersList';
import { UserDetailPage } from 'containers/UserDetailPage';
import { NotificationContainer } from 'react-notifications';
import { GrabData } from 'components/GrabData';
import { StatusBar } from 'components/StatusBar';
import { BatchList } from 'containers/BatchList';
import { BatchDetail } from 'containers/BatchDetail';
import { CustomersList } from 'containers/CustomersList/CustomersList';
import { CustomerDetailPage } from 'containers/CustomerDetailPage/CustomerDetailPage';
import { CustomerStatistics } from 'containers/CustomerStatistics';
import { ToolsList } from 'containers/ToolsList';
import { ToolDetailPage } from 'containers/ToolDetailPage';
import { DashboardPage } from 'containers/DashboardPage/DashboardPage';
import { UserStatistics } from 'containers/UserStatistics';
import { AdminSidebar } from 'components/AdminSidebar/AdminSidebar';
import { UserGroupsList } from 'containers/UserGroupsList';
import { UserGroupUsers } from 'containers/UserGroupUsers';
import { UserGroupDetail } from 'containers/UserGroupDetail';
import { SettingsPage } from 'containers/SettingsPage';
import { SettingDetailPage } from 'containers/SettingDetailPage/SettingDetailPage';
import { AssetsList } from 'containers/AssetsList';
import { AIStatistics } from 'containers/AIStatistics';

const App = () => {
  const [collapsed, setCollapsed] = useState(false);

  const getAuthInfo = async () => {
    const session = await fetchAuthSession();

    try {
      const { accessToken } = session.tokens;
      const { payload } = accessToken;
      const userGroups = payload['cognito:groups'];

      if (userGroups.includes('Admin')) {
        const { username } = payload;
        localStorage.setItem('@userName', username);
        localStorage.setItem('@accessToken', accessToken.toString());
      } else {
        signOut();
      }
    } catch (error) {
      localStorage.clear();
      signOut();
    }
  };

  useEffect(() => {
    getAuthInfo();
  }, []);

  return (
    <Authenticator>
      {() => (
        <Router>
          <Container fluid className='main-container'>
            <div className='layout-wrapper'>
              <AdminSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
              <div
                className={`page-content-wrapper ${
                  collapsed ? 'collapsed' : ''
                }`}
              >
                <div className='page-content-body'>
                  <StatusBar />
                  <GrabData />
                  <Routes>
                    <Route exact path='/' element={<DashboardPage />} />
                    <Route exact path='/media' element={<MediaList />} />
                    <Route
                      exact
                      path='/media/:media_id'
                      element={<MediaDetail />}
                    />
                    <Route exact path='/batch' element={<BatchList />} />
                    <Route
                      exact
                      path='/batch/:batch_id'
                      element={<BatchDetail />}
                    />
                    
                    <Route path='/tools' element={<ToolsList />} />
                    <Route
                      path='/tools/:tool_id'
                      element={<ToolDetailPage />}
                    />
                    {/* USER GROUPS ROUTING */}
                    <Route path='/user-groups' element={<UserGroupsList />} />
                    <Route path='/user-groups/:group_name' element={<UserGroupDetail />} />
                    <Route
                      path='/user-groups/users/:group_name'
                      element={<UserGroupUsers />}
                    />
                    {/* USERS ROUTING */}
                    <Route path='/users' element={<UsersList />} />
                    <Route
                      path='/users/:user_id'
                      element={<UserDetailPage />}
                    />
                    <Route
                      exact
                      path='/statistics/users/:user_id'
                      element={<UserStatistics />}
                    />
                    {/* CUSTOMERS ROUTING */}
                    <Route path='/customers' element={<CustomersList />} />
                    <Route
                      path='/customers/:customer_id'
                      element={<CustomerDetailPage />}
                    />
                    <Route
                      exact
                      path='/statistics/customers/:customer_id'
                      element={<CustomerStatistics />}
                    />
                    {/* SETTINGS ROUTING */}
                    <Route path='/settings' element={<SettingsPage />} />
                    <Route
                      exact
                      path='/settings/:setting_key'
                      element={<SettingDetailPage />}
                    />
                    <Route exact path='/assets' element={<AssetsList />} />
                    <Route exact path='/ai-usages' element={<AIStatistics />} />
                  </Routes>
                </div>
              </div>
            </div>
            <NotificationContainer />
          </Container>
        </Router>
      )}
    </Authenticator>
  );
};

export default App;
