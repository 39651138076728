import { actionConstants } from 'redux-layer/constants';
import {
  startReqActionType,
  successReqActionType,
  failReqActionType,
  authHeader,
  processResponse
} from 'helper/requestHelper';
import { NotificationManager } from 'react-notifications';
import { getEnvironment } from 'helper/utils';

export const getCustomers = () => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.GET_CUSTOMERS) });
  try {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/customers`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.GET_CUSTOMERS),
      payload: retData
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.GET_CUSTOMERS) });
  }
};

export const addCustomer = (payload, callback) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.ADD_CUSTOMER) });
  try {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload)
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/customers`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.ADD_CUSTOMER),
      payload: retData
    });
    NotificationManager.success(
      'New cutomer has been created.',
      'Success'
    );
    if (callback) {
      callback(retData.id);
    }
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.ADD_CUSTOMER) });
  }
};

export const updateCustomer = (id, payload) => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.UPDATE_CUSTOMER) });
  try {
    const requestOptions = {
      method: 'put',
      headers: authHeader(),
      body: JSON.stringify(payload)
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/customers/${id}`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.UPDATE_CUSTOMER),
      payload: retData
    });
    NotificationManager.success(
      'Customer informatino is updated.',
      'Success'
    );
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.UPDATE_CUSTOMER) });
  }
};

export const regenerateAPIKey = id => async dispatch => {
  dispatch({
    type: startReqActionType(actionConstants.REGENERATE_API_KEY_CUSTOMER)
  });
  try {
    const requestOptions = {
      method: 'put',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/customers/${id}/key`,
      requestOptions
    );
    const retData = await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.REGENERATE_API_KEY_CUSTOMER),
      payload: retData
    });
    NotificationManager.success(
      'API key has been updated.',
      'Success'
    );
  } catch (error) {
    dispatch({
      type: failReqActionType(actionConstants.REGENERATE_API_KEY_CUSTOMER)
    });
  }
};

export const deleteCustomer = id => async dispatch => {
  dispatch({ type: startReqActionType(actionConstants.REMOVE_CUSTOMER) });
  try {
    const requestOptions = {
      method: 'delete',
      headers: authHeader()
    };

    const data = await fetch(
      `${getEnvironment().API_URL}admin/customers/${id}`,
      requestOptions
    );
    await processResponse(data);
    dispatch({
      type: successReqActionType(actionConstants.REMOVE_CUSTOMER),
      payload: { id }
    });
  } catch (error) {
    dispatch({ type: failReqActionType(actionConstants.REMOVE_CUSTOMER) });
  }
};
