/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import './DashboardPage.scss';
import { NotificationManager } from 'react-notifications';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAIRecentHistory,
  getAPIServers,
  getEc2Status,
  getQueueSizes,
  getServiceBalances,
  getSettings,
  getTools,
  requestClearBatch,
  updateSetting
} from 'redux-layer/actions';
import Switch from 'react-switch';
import { Badge, Button } from 'react-bootstrap';
import { ToolsRender } from './ToolsRender';
import { getEnvironment } from 'helper/utils';
import { SQSRender } from './SQSRender';
import { EC2Render } from './EC2Render';
import { APIServerRender } from './APIServerRender';
import { ColorLabel } from 'components/ColorLabel';
import { AIToolUsageMeter } from 'components/AIToolUsageMeter';

const DashboardPage = props => {
  const dispatch = useDispatch();

  const {
    isLoadingTools,
    isLoadingQueueSizes,
    isLoadingEC2Status,
    isLoadingAPIServers,
    isLoadingRecentAiUsage,
    allSettings,
    balances
  } = useSelector(state => ({
    isLoadingTools: state.tools.isLoadingTools,
    isLoadingQueueSizes: state.queue.isLoadingQueueSizes,
    isLoadingEC2Status: state.ec2.isLoadingEC2Status,
    isLoadingAPIServers: state.ec2.isLoadingAPIServers,
    isLoadingRecentAiUsage: state.statistic.isLoadingRecentAiUsage,
    allSettings: state.settings.allSettings,
    balances: state.services.balances
  }));

  const [autoScalingFlag, setAutoScalingFlag] = useState(true);

  useEffect(() => {
    onRefresh();
    dispatch(getSettings());
    dispatch(getServiceBalances());
    const intervalCall = setInterval(() => {
      onRefresh();
    }, 60000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  useEffect(() => {
    const autoScalingValues = allSettings['is_auto_scaling_on'];
    if (autoScalingValues) {
      setAutoScalingFlag(autoScalingValues.value === '1' ? true : false);
    }
  }, [allSettings]);

  const onChangeAutoScaling = value => {
    const autoScalingValues = allSettings['is_auto_scaling_on'];
    if (autoScalingValues) {
      setAutoScalingFlag(value);

      if (value) {
        autoScalingValues.value = '1';
      } else {
        autoScalingValues.value = '0';
      }
      dispatch(
        updateSetting(autoScalingValues.key, autoScalingValues, () => {
          NotificationManager.success(
            'Your setting has been updated.',
            'Auto Scaling',
            2000
          );
        })
      );
    }
  };

  const onRefresh = () => {
    onReloadTools();

    onReloadQueue();

    onReloadEC2();

    onReloadAPIServers();

    onReloadAiUsage();
  };

  const environmentName = getEnvironment().ENVIRONMENT_NAME;

  const onReloadQueue = () => {
    if (!isLoadingQueueSizes) {
      dispatch(getQueueSizes());
    }
  };

  const onReloadTools = () => {
    if (!isLoadingTools) {
      dispatch(getTools());
    }
  };

  const onReloadEC2 = () => {
    if (!isLoadingEC2Status) {
      dispatch(getEc2Status());
    }
  };

  const onReloadAPIServers = () => {
    if (!isLoadingAPIServers) {
      dispatch(getAPIServers());
    }
  };

  const onReloadAiUsage = () => {
    if (!isLoadingRecentAiUsage) {
      dispatch(getAIRecentHistory());
    }
  }

  return (
    <div className='dashboard-page'>
      <div className='page-header'>
        <h1>
          Dashboard <Badge bg='danger'>{environmentName}</Badge>
        </h1>
        <div>
          <Button variant='primary' className='me-2' onClick={onRefresh}>
            <i className={`fas fa-redo`} /> Refresh
          </Button>
        </div>
      </div>
      <div className='contents'>
        <div className='row'>
          <div className='col-md-5'>
            <div className='section-header'>
              <h3>SQS</h3>
              <Button
                className='btn btn-primary'
                onClick={onReloadQueue}
                disabled={isLoadingQueueSizes}
              >
                <i
                  className={`fas fa-redo ${
                    isLoadingQueueSizes ? 'fa-spin' : ''
                  }`}
                />{' '}
                {!isLoadingQueueSizes ? 'Refresh' : 'Loading'}
              </Button>
            </div>
            <SQSRender />
          </div>
          <div className='col-md-2'>
            <div className='section-header'>
              <h3>Actions</h3>
            </div>
            <div className='action-list'>
              <div className='action-item'>
                <div className='action-name'>Auto Scaling:</div>
                <Switch
                  onChange={onChangeAutoScaling}
                  checked={autoScalingFlag}
                />
              </div>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='section-header'>
              <h3>Balances</h3>
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <ColorLabel
                  label='Stability Diffusion'
                  value={balances['stability'] || 'Coming Soon'}
                  color='purple'
                />
              </div>
              <div className='col-md-3'>
                <ColorLabel
                  label='Leonardo AI'
                  value={balances['leonardo'] || 'Coming Soon'}
                  color='blue'
                />
              </div>
              <div className='col-md-3'>
                <ColorLabel
                  label='Haiper AI'
                  value={
                    balances['haiper'] && typeof balances['haiper'] === 'object'
                      ? `${balances['haiper']['videos_available']}`
                      : 'Coming Soon'
                  }
                  color='purple'
                />
              </div>
              <div className='col-md-3'>
                <ColorLabel
                  label='OpenAI'
                  value={balances['openai'] || 'Coming Soon'}
                  color='blue'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='section-header'>
              <h3>AI Services Usage - 1 hour</h3>
              <Button
                className='btn btn-primary'
                onClick={onReloadAiUsage}
                disabled={isLoadingRecentAiUsage}
              >
                <i
                  className={`fas fa-redo ${
                    isLoadingRecentAiUsage ? 'fa-spin' : ''
                  }`}
                />{' '}
                {!isLoadingRecentAiUsage ? 'Refresh' : 'Loading'}
              </Button>
            </div>
            <AIToolUsageMeter />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='section-header'>
              <h3>API Servers</h3>
              <Button
                className='btn btn-primary'
                onClick={onReloadAPIServers}
                disabled={isLoadingAPIServers}
              >
                <i
                  className={`fas fa-redo ${
                    isLoadingAPIServers ? 'fa-spin' : ''
                  }`}
                />{' '}
                {!isLoadingAPIServers ? 'Refresh' : 'Loading'}
              </Button>
            </div>
            <APIServerRender />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='section-header'>
              <h3>Main Pipeline</h3>
              <Button
                className='btn btn-primary'
                onClick={onReloadEC2}
                disabled={isLoadingEC2Status}
              >
                <i
                  className={`fas fa-redo ${
                    isLoadingEC2Status ? 'fa-spin' : ''
                  }`}
                />{' '}
                {!isLoadingEC2Status ? 'Refresh' : 'Loading'}
              </Button>
            </div>
            <EC2Render />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <div className='section-header'>
              <h3>Tool Status</h3>
              <Button
                className='btn btn-primary'
                onClick={onReloadTools}
                disabled={isLoadingTools}
              >
                <i
                  className={`fas fa-redo ${isLoadingTools ? 'fa-spin' : ''}`}
                />{' '}
                {!isLoadingTools ? 'Refresh' : 'Loading'}
              </Button>
            </div>
            <ToolsRender />
          </div>
        </div>
      </div>
    </div>
  );
};

export { DashboardPage };
