import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import './AdminSidebar.scss';
import { useEffect, useState } from 'react';
import { signOut } from 'aws-amplify/auth';
import { ENV_OPTIONS } from 'helper/constants';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { Link } from 'react-router-dom';

const AdminSidebar = props => {
  const [env, setEnv] = useState(ENV_OPTIONS[0]);

  const [windowWidth, setWindowWidth] = useState(1024);
  const { collapsed, setCollapsed } = props;

  useEffect(() => {
    const envString = localStorage.getItem('myEnv');
    if (envString) {
      const envValue = JSON.parse(envString);
      setEnv(envValue);
    }
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (windowWidth < 1024) {
      setCollapsed(true);
    }
  }, [windowWidth]);

  const onChangeEnvironment = value => {
    localStorage.setItem('myEnv', JSON.stringify(value));
    setEnv(value);
    document.location.reload();
  };

  return (
    <div className='sidebar-wrapper'>
      <Sidebar
        collapsed={collapsed}
        backgroundColor='#1d1d1d'
        collapsedWidth='100px'
      >
        <div className='sidebar-header'>
          <Button
            variant='outline-light'
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <i className='fas fa-bars' />
          </Button>
          <h3>Vimmerse</h3>
        </div>
        <div className='sidebar-header'>
          <Select
            className='env-selector'
            classNamePrefix='env-select'
            onChange={onChangeEnvironment}
            options={ENV_OPTIONS}
            value={env}
          />
        </div>
        <Menu iconShape='square'>
          <Link to='/'>
            <MenuItem icon={<i className='fas fa-dashboard' />}>
              Dashboard
            </MenuItem>
          </Link>
          <Link to='/media'>
            <MenuItem icon={<i className='fas fa-video' />}>Medias</MenuItem>
          </Link>
          <Link to='/batch'>
            <MenuItem icon={<i className='fas fa-inbox' />}>Batches</MenuItem>
          </Link>
          <Link to='/assets'>
            <MenuItem icon={<i className='fas fa-camera' />}>Assets</MenuItem>
          </Link>
          <hr />
          <Link to='/ai-usages'>
            <MenuItem icon={<i className='fas fa-dashboard' />}>
              Statistics - AI
            </MenuItem>
          </Link>
          <hr />
          <Link to='/users'>
            <MenuItem icon={<i className='fas fa-users' />}>
              Search Users
            </MenuItem>
          </Link>
          <Link to='/user-groups'>
            <MenuItem icon={<i className='fas fa-users-rectangle' />}>
              User Groups
            </MenuItem>
          </Link>
          <hr />
          <Link to='/customers'>
            <MenuItem icon={<i className='fas fa-user-tie' />}>
              Customers
            </MenuItem>
          </Link>
          <hr />
          <Link to='/tools'>
            <MenuItem icon={<i className='fas fa-tools' />}>Tools</MenuItem>
          </Link>
          <hr />
          <Link to='/settings'>
            <MenuItem icon={<i className='fas fa-gears' />}>Settings</MenuItem>
          </Link>
        </Menu>
        <div className='sidebar-footer'>
          <Button variant='danger' onClick={signOut}>
            <i className='fas fa-right-from-bracket' /> Sign Out
          </Button>
        </div>
      </Sidebar>
    </div>
  );
};

export { AdminSidebar };
