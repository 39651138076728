import { actionConstants } from '../constants';
import { failReqActionType, startReqActionType, successReqActionType } from 'helper/requestHelper';

const initialState = {
  isLoadingStatistics: false,
  isLoadingRecentAiUsage: false,

  userStatistics: [],
  customerStatistics: [],
  aiUsageStatistics: {},
  recentAIUsages: [],
  error: null
};

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.GET_USER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: true
      };
    case successReqActionType(actionConstants.GET_USER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: false,
        userStatistics: action.payload
      };

    case startReqActionType(actionConstants.GET_CUSTOMER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: true
      };
    case successReqActionType(actionConstants.GET_CUSTOMER_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: false,
        customerStatistics: action.payload
      };
    
    case startReqActionType(actionConstants.GET_AI_USAGE_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: true
      };
    case successReqActionType(actionConstants.GET_AI_USAGE_STATISTICS):
      return {
        ...state,
        isLoadingStatistics: false,
        aiUsageStatistics: action.payload
      };
    
    case startReqActionType(actionConstants.GET_AI_RECENT_USAGE):
      return {
        ...state,
        isLoadingRecentAiUsage: true
      }
    case successReqActionType(actionConstants.GET_AI_RECENT_USAGE):
      return {
        ...state,
        recentAIUsages: action.payload,
        isLoadingRecentAiUsage: false,
      }
    case failReqActionType(actionConstants.GET_AI_RECENT_USAGE):
      return {
        ...state,
        recentAIUsages: [],
        isLoadingRecentAiUsage: false,
      }
    default:
      return state;
  }
};

export default statisticsReducer;
