import { useState, useEffect } from 'react';
import './UserDetailPage.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  Alert,
  Modal,
  Dropdown,
  Badge
} from 'react-bootstrap';
import {
  updateUserCredit,
  createOrUpdateUser,
  deleteUser,
  disableUser,
  enableUser,
  updateUserDiscount,
  addUserToGroup,
  removeUserFromGroup,
  getUserDetail,
  getGroupsFromUser,
  getUserCreditHistory
} from 'redux-layer/actions';
import { getAttributeFromUser, getUserAttribute } from 'helper/utils';
import { NotificationManager } from 'react-notifications';
import { DISCOUNT_OPTIONS, PRICE_PLANS, VIMMERSE_CUSTOMER_ID } from 'helper/constants';
import { UserCreditHistoryDialog } from './UserCreditHistory';

const UserDetailPage = props => {
  const { user_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [givenName, setGivenName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [preferredUsername, setPreferredUsername] = useState('');
  // const [apiKey, setApiKey] = useState('');

  const [phone, setPhone] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');

  const [showUpdateCredit, setShowUpdateCredit] = useState(false);
  const [newCreditAmount, setNewCreditAmount] = useState(0);
  const [showSetDiscount, setShowSetDiscount] = useState(false);
  const [discount, setDiscount] = useState(DISCOUNT_OPTIONS[0]);
  const [showCreditHistory, setShowCreditHistory] = useState(false);
  const [creditHistory, setCreditHistory] = useState([]);

  const { allCustomers, allUsers } = useSelector(state => ({
    allCustomers: state.customers.allCustomers,
    allUsers: state.users.allUsers
  }));

  useEffect(() => {
    if (user_id !== 'new') {
      dispatch(getGroupsFromUser(user_id));
    }
  }, []);

  useEffect(() => {
    if (user_id && user_id !== 'new') {
      if (allUsers[user_id.toLowerCase()]) {
        const currentUser = allUsers[user_id.toLowerCase()];
        setUsername(currentUser['Username']);

        const Attributes = currentUser.Attributes || currentUser.UserAttributes;

        setGivenName(getUserAttribute(Attributes, 'given_name') || '');
        setPreferredUsername(
          getUserAttribute(Attributes, 'preferred_username') ||
            currentUser['Username']
        );

        setEmail(getUserAttribute(Attributes, 'email') || '');

        setPhone(getUserAttribute(Attributes, 'phone_number') || '');
        setFamilyName(getUserAttribute(Attributes, 'family_name') || '');
        setWebsite(getUserAttribute(Attributes, 'website') || '');
        setDescription(getUserAttribute(Attributes, 'custom:bio') || '');
        setDiscount(
          DISCOUNT_OPTIONS.find(
            co => co.value === getUserAttribute(Attributes, 'custom:discount')
          ) || null
        );
      } else {
        dispatch(getUserDetail(user_id.toLowerCase()));
      }
    }
  }, [user_id, allUsers, dispatch]);

  const currentUser = allUsers[user_id.toLowerCase()];

  const handleSubmit = () => {
    if (username === '') {
      NotificationManager.error('Username is required', 'Submission Error');
      return;
    }
    if (preferredUsername === '') {
      NotificationManager.error('Handle is required', 'Submission Error');
      return;
    }
    if (email === '') {
      NotificationManager.error('Email is required', 'Submission Error');
      return;
    }

    let payload = {
      username,
      email,
      given_name: givenName,
      preferred_username: preferredUsername,
      phone_number: phone,
      family_name: familyName,
      website,
      custom_bio: description
    };

    dispatch(createOrUpdateUser(payload));
    // NotificationManager.success('You are succeed to submit your user', 'Submission Success');
  };

  const onUpdateCredit = () => {
    if (newCreditAmount) {
      const payload = {
        username,
        amount: newCreditAmount
      };
      dispatch(updateUserCredit(payload));
      setShowUpdateCredit(false);
    }
  };

  const onViewCreditHistory = async () => {
    try {
      const response = await getUserCreditHistory(user_id);
      setCreditHistory(response);
      setShowCreditHistory(true);
    } catch (error) {
      console.log(error);
    }
  }

  const onUpdateDiscount = () => {
    if (discount) {
      const payload = {
        username,
        discount: discount.value
      };
      dispatch(updateUserDiscount(payload));
      setShowSetDiscount(false);
    }
  };

  const onDeleteUser = () => {
    dispatch(deleteUser(user_id.toLowerCase()));
    navigate('/users');
  };

  const onDisableUser = () => {
    dispatch(disableUser(user_id.toLowerCase()));
    navigate('/users');
  };

  const onEnablesUser = () => {
    dispatch(enableUser(user_id.toLowerCase()));
    navigate('/users');
  };

  // const onGenerateApiKey = async () => {
  //   const response = await generateApiKey(user_id);
  //   setApiKey(response);
  // }

  // const onAddAccountToDeveloper = () => {
  //   dispatch(addUserToGroup(user_id.toLowerCase(), 'Developer'));
  // };

  // const onRemoveAccountFromDeveloper = () => {
  //   dispatch(removeUserFromGroup(user_id.toLowerCase(), 'Developer'));
  // };

  // const onAddAccountToStaff = () => {
  //   dispatch(addUserToGroup(user_id.toLowerCase(), 'Staff'));
  // };

  // const onRemoveAccountFromStaff = () => {
  //   dispatch(removeUserFromGroup(user_id.toLowerCase(), 'Staff'));
  // };

  const customersOptions = Object.values(allCustomers).map(co => ({
    value: co.id,
    label: co.name,
    customer_id: co.customer_id,
    customer_key: co.key || ''
  }));

  const onViewContents = () => {
    const selectedCustomer = customersOptions.find(
      co => co.customer_id === VIMMERSE_CUSTOMER_ID
    );
    if (selectedCustomer) {
      localStorage.setItem('customer', JSON.stringify(selectedCustomer));
      navigate(`/media?created_by=${user_id.toLowerCase()}`);
    }
  };

  const isUserDeveloper =
    currentUser &&
    currentUser.groups &&
    currentUser.groups.find(gr => gr.GroupName === 'Developer');
  const isUserStaff =
    currentUser &&
    currentUser.groups &&
    currentUser.groups.find(gr => gr.GroupName === 'Staff');
  const isUserAdmin =
    currentUser &&
    currentUser.groups &&
    currentUser.groups.find(gr => gr.GroupName === 'Admin');
  
  const tier = currentUser ? getAttributeFromUser(currentUser, 'custom:tier') : null;
  const pricePlan = tier ? PRICE_PLANS[tier] : null;

  return (
    <div className='user-detail-page'>
      <div className='page-header'>
        <h1>
          {user_id === 'new' ? 'New User' : user_id.toLowerCase()}
          {!(currentUser && currentUser.Enabled) ? (
            <Badge className='bg-secondary ms-2'>Disabled</Badge>
          ) : (
            ''
          )}
          {currentUser && isUserDeveloper ? (
            <Badge className='bg-primary ms-2'>Developer</Badge>
          ) : (
            ''
          )}
          {currentUser && isUserStaff ? (
            <Badge className='bg-info ms-2'>Staff</Badge>
          ) : (
            ''
          )}
          {currentUser && isUserAdmin ? (
            <Badge className='bg-danger ms-2'>Admin</Badge>
          ) : (
            ''
          )}
        </h1>
        <div className='actions'>
          <Dropdown
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Dropdown.Toggle variant='primary' id='dropdown-play' size='md'>
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                eventKey='statistics'
                as={Link}
                to={`/statistics/users/${user_id.toLowerCase()}`}
              >
                Statistics
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item eventKey='view-contents' onClick={onViewContents}>
                View Contents
              </Dropdown.Item>
              <Dropdown.Divider />
              {currentUser && currentUser.Enabled ? (
                <Dropdown.Item eventKey='disable-user' onClick={onDisableUser}>
                  Disable user access
                </Dropdown.Item>
              ) : (
                <Dropdown.Item eventKey='enable-user' onClick={onEnablesUser}>
                  Enable user access
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              {/* <Dropdown.Item eventKey='generate-api-key' onClick={onGenerateApiKey}>
                {
                  apiKey ? 'Regenerate API Key' : 'Generate API Key'
                }
                
              </Dropdown.Item>
              <Dropdown.Divider /> */}
              <Dropdown.Item eventKey='delete' onClick={onDeleteUser}>
                Delete
              </Dropdown.Item>
              <Dropdown.Divider />
              {/* {developerUser ? (
                <Dropdown.Item
                  eventKey='remove-developer'
                  onClick={onRemoveAccountFromDeveloper}
                >
                  Remove account from Developer
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  eventKey='add-developer'
                  onClick={onAddAccountToDeveloper}
                >
                  Add account to Developer
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              {staffUser ? (
                <Dropdown.Item
                  eventKey='remove-staff'
                  onClick={onRemoveAccountFromStaff}
                >
                  Remove account from Staff
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  eventKey='add-staff'
                  onClick={onAddAccountToStaff}
                >
                  Add account to Staff
                </Dropdown.Item>
              )} */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className='container'>
        <h3>Required Fields</h3>
        <div className='row'>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel required>Username *</FormLabel>
              <FormControl
                type='text'
                value={username}
                disabled={user_id && user_id !== 'new'}
                required
                placeholder='Username'
                onChange={e => setUsername(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel>Email *</FormLabel>
              <FormControl
                type='email'
                value={email}
                required
                placeholder='Email'
                onChange={e => setEmail(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel required>Handle *</FormLabel>
              <FormControl
                type='text'
                value={preferredUsername}
                required
                placeholder='Handle'
                onChange={e => setPreferredUsername(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>

        <h3>Optional Fields</h3>
        <div className='row'>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel>First Name</FormLabel>
              <FormControl
                type='text'
                value={givenName}
                placeholder='First name'
                onChange={e => setGivenName(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel>Last Name</FormLabel>
              <FormControl
                type='text'
                value={familyName}
                placeholder='Last Name'
                onChange={e => setFamilyName(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          {/* <div className='col-md-6'>
            <FormGroup>
              <FormLabel>Website</FormLabel>
              <FormControl
                type='text'
                value={website}
                placeholder='Website URL'
                onChange={e =>setWebsite(e.target.value)}
              />
            </FormGroup>
          </div> */}
          <div className='col-md-6'>
            <FormGroup>
              <FormLabel>Bio</FormLabel>
              <FormControl
                type='text'
                value={description}
                placeholder='Bio'
                onChange={e => setDescription(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Button
              onClick={handleSubmit}
              variant='primary'
              disabled={username === ''}
            >
              Save
            </Button>
          </div>
        </div>

        <div className='mb-3'>
          <Alert variant='info'>
            For new users, temporary password is VimmerseUser2023
          </Alert>
        </div>

        {currentUser && (
          <>
            <hr />
            <div className='row'>
              <div className='col-md-3'>
                <FormGroup>
                  <FormLabel>Credits</FormLabel>
                  <p className='fw-bold'>
                    {getAttributeFromUser(currentUser, 'custom:credit')}
                  </p>
                </FormGroup>
              </div>
              <div className='col-md-3'>
                <FormGroup>
                  <FormLabel>Plan</FormLabel>
                  <p className='fw-bold'>
                    {getAttributeFromUser(currentUser, 'custom:tier') || 'Free'}
                  </p>
                </FormGroup>
              </div>
              <div className='col-md-3'>
                <FormGroup>
                  <FormLabel>Auto Refill?</FormLabel>
                  <p className='fw-bold'>
                    {getAttributeFromUser(currentUser, 'custom:auto_refill') &&
                    getAttributeFromUser(currentUser, 'custom:auto_refill') ===
                      '1'
                      ? 'Yes'
                      : 'No'}
                  </p>
                </FormGroup>
              </div>
              <div className='col-md-3'>
                <FormGroup>
                  <FormLabel>Description</FormLabel>
                  <p className='fw-bold'>
                    {pricePlan ? pricePlan.description : ''}
                  </p>
                </FormGroup>
              </div>
              <div className='col-md-12'>
                <Button
                  variant='primary'
                  onClick={() => setShowUpdateCredit(true)}
                >
                  Update Credit
                </Button>
                <Button
                  variant='primary'
                  onClick={() => onViewCreditHistory()}
                  className='ms-4'
                >
                  See Credit History
                </Button>
              </div>
            </div>
          </>
        )}
        {/* {
          apiKey && (
            <>
              <h5>API Key</h5>
              <div className='row'>
                <div className='col-md-6'>
                  <FormGroup>
                    <FormControl
                      type='text'
                      value={apiKey}
                      placeholder='API KEY'
                      readOnly={true}
                    />
                  </FormGroup>
                </div>
              </div>
            </>
          )
        } */}

        {/* <div className='row'>
          <div className='col'>
            <FormGroup>
              <FormLabel>Phone number</FormLabel>
              <FormControl
                type='text'
                value={phone}
                placeholder='+1 (234) 567-8901'
                onChange={e =>setPhone(e.target.value)}
              />
            </FormGroup>
          </div>
        </div> */}
        {currentUser && (
          <>
            <hr />
            <h3>Groups</h3>
            <div className='group-list'>
              {currentUser &&
                currentUser.groups &&
                currentUser.groups.map(gr => (
                  <Badge className='bg-info ms-2 me-2'>{gr.GroupName}</Badge>
                ))}
            </div>
          </>
        )}
      </div>
      {showUpdateCredit && (
        <Modal
          show={true}
          onHide={() => setShowUpdateCredit(false)}
          backdrop='static'
          size='md'
        >
          <Modal.Header>Add/Remove Credit</Modal.Header>
          <Modal.Body>
            <FormGroup className='mb-3'>
              <FormLabel>How many credit do you want to add/remove?</FormLabel>
              <FormControl
                type='number'
                value={newCreditAmount}
                placeholder='Please input new credit amount'
                onChange={e => setNewCreditAmount(e.target.value)}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={onUpdateCredit}>
              Save
            </Button>
            <Button
              variant='secondary'
              onClick={() => setShowUpdateCredit(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {showSetDiscount && (
        <Modal
          show={true}
          onHide={() => setShowSetDiscount(false)}
          backdrop='static'
          size='md'
        >
          <Modal.Header>Set Discount</Modal.Header>
          <Modal.Body>
            <FormGroup className='mb-3'>
              <FormLabel>Discount Options</FormLabel>
              <Select
                options={DISCOUNT_OPTIONS}
                value={discount}
                onChange={setDiscount}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={onUpdateDiscount}>
              Save
            </Button>
            <Button
              variant='secondary'
              onClick={() => setShowSetDiscount(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      {showCreditHistory && (
        <UserCreditHistoryDialog
          onClose={() => setShowCreditHistory(false)}
          creditHistory={creditHistory}
        />
      )}
    </div>
  );
};

export { UserDetailPage };
