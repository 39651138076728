import { useEffect, useState } from 'react';
import './BatchDetail.scss';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  deleteBatch,
  getBatch,
  processBatch,
  readyBatch,
  updateBatch
} from 'redux-layer/actions/batchActions';
import {
  Button,
  ButtonGroup,
  Card,
  ProgressBar,
  Pagination
} from 'react-bootstrap';
import {
  convertUTCtoLocal,
  getMotionTypesFromBatch,
  getPosePresetsFromOldBatch
} from 'helper/utils';
import { BatchDialog } from 'components/BatchDialog';
import { NotificationManager } from 'react-notifications';
import { BatchMediaItem } from './BatchMediaItem';
import {
  BATCH_STATUS_NEW,
  BATCH_STATUS_PAID,
  BATCH_STATUS_PROCESSING,
  BATCH_STATUS_READY,
  BATCH_STATUS_SUCCESS,
  DEFAULT_SUBMIT_PARAMS,
  GENERATE_VIDEO
} from 'helper/constants';

const BatchDetail = props => {
  const dispatch = useDispatch();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const { batch_id } = useParams();

  const [pageNumber, setPageNumber] = useState(0);

  const navigate = useNavigate();

  const { isProcessRequesting } = useSelector(state => ({
    isProcessRequesting: state.batch.isProcessRequesting
  }));

  const batch = props.allBatch[batch_id];

  const envString = localStorage.getItem('myEnv');
  let envName = 'prod';
  if (envString) {
    const envValue = JSON.parse(envString);
    envName = envValue.value;
  }

  const vimmerseBatchTable =
    envName === 'prod' ? 'VimmerseBatch-prod' : 'VimmerseBatch-dev';

  const websiteUrl =
    envName === 'prod' ? 'https://www.vimmerse.net' : 'https://www.vimmerse.co';
  const mediaURL =
    envName === 'prod'
      ? 'https://media.vimmerse.net'
      : 'https://dev-media.vimmerse.net';

  const orderLink = `${websiteUrl}/order/${batch_id}`;

  useEffect(() => {
    dispatch(getBatch(batch_id));
  }, []);

  if (!batch) {
    return '';
  }

  const {
    medias = [],
    filenames,
    status,
    pose_preset,
    motion_types,
    all_medias = []
  } = batch;

  const motionTypesCount = pose_preset
    ? pose_preset.length
    : motion_types
    ? motion_types.split(',').length
    : 3;

  const PAGE_SIZE = Math.ceil(8 / motionTypesCount);

  const newFileMap =
    filenames &&
    filenames.map(file => {
      const sourcePath = `${process.env.REACT_APP_DISTRIBUTION_URL}batch-uploads/${batch_id}/${file}`;
      return (
        <div className='col-2' key={file}>
          <img src={sourcePath} alt='' className='w-100' />
          <p className='mt-2 text-word-break'>{file}</p>
        </div>
      );
    });

  const totalPageSize = Math.ceil(medias.length / PAGE_SIZE);

  const paginationBar = () => {
    const items = [];
    for (let i = 0; i < totalPageSize; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === pageNumber}
          onClick={() => setPageNumber(i)}
        >
          {i + 1}
        </Pagination.Item>
      );
    }
    return <Pagination>{items}</Pagination>;
  };

  const pageContent = () => {
    const currentMedia = medias.slice(
      PAGE_SIZE * pageNumber,
      PAGE_SIZE * (pageNumber + 1)
    );

    return (
      <Card>
        <Card.Body>
          {currentMedia.map(media => {
            let grabbedMedia = all_medias.find(
              item => item.id === media.media_id
            );
            grabbedMedia = {
              ...grabbedMedia,
              ...media
            };
            return (
              <BatchMediaItem
                batch={batch}
                media={grabbedMedia}
                key={media.id}
              />
            );
          })}
        </Card.Body>
      </Card>
    );
  };

  // const onGrabBatch = async () => {
  //   if (![BATCH_STATUS_NEW, BATCH_STATUS_PROCESSING].includes(batch.status)) {
  //     NotificationManager.info(
  //       'It may take some time to grab all contents. Please wait.',
  //       'Grabbing Started'
  //     );
  //     const response = await grabMediaToBatch(batch_id);
  //     NotificationManager.success(
  //       response['data']['grab_count'] +
  //         ' of ' +
  //         medias.length +
  //         ' contents are grabbed.',
  //       'Success'
  //     );
  //     // if (response['grab_count'] === medias.length) {

  //     // }
  //   }
  // };

  const onEdit = () => {
    setShowEditDialog(true);
  };

  const onResubmit = () => {
    dispatch(processBatch(batch_id));
  };

  const onUpdateBatch = payload => {
    const newBatch = {
      ...payload
    };
    dispatch(updateBatch(batch_id, newBatch));
    setShowEditDialog(false);
  };

  const onReady = async () => {
    // Disable excel_link
    // if (!batch.excel_link) {
    //   NotificationManager.error('Please set Excel Link before show to customers.', 'Not Ready Yet!');
    //   return;
    // }
    const sendNotification = () => {
      dispatch(readyBatch(batch_id));
      NotificationManager.success(
        'Just sent an email with order link to customer.',
        'Email sent'
      );
    };
    if (batch.notified_at) {
      if (
        window.confirm(
          'This batch has already been notified. Do you want to notify again?'
        )
      ) {
        sendNotification();
      }
    } else {
      sendNotification();
    }
  };

  const download = function (data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', batch_id + '.csv');
    a.click();
  };

  const onDownloadCsvTemplate = async () => {
    if (!medias.length) {
      NotificationManager.error(
        'Please process all content first.',
        'Not Ready Yet!'
      );
      return;
    }
    // Generate CSV Data
    const nowString = convertUTCtoLocal(batch.created_at).format('MM/DD/YYYY');
    const csvRows = [];
    csvRows.push(['Order:', batch_id].join(','));
    csvRows.push(['Name:', batch?.name, '', 'To Customers'].join(','));
    csvRows.push(
      [
        'Email:',
        batch?.email,
        '',
        'Please provide your feedback and customization requests per item in the customer feedback column below'
      ].join(',')
    );
    csvRows.push(['Title:', batch?.title].join(','));
    csvRows.push(['Industry:', batch?.industry].join(','));
    csvRows.push(
      ['Date:', convertUTCtoLocal(batch.created_at).format('MM/DD/YYYY')].join(
        ','
      )
    );
    csvRows.push(['Order Page:', orderLink].join(','));
    csvRows.push('');
    csvRows.push('');

    let index = 1;
    csvRows.push(
      [
        'No',
        'Image Name',
        'Media ID',
        'Motion Config',
        'Creation Date',
        'Update Date',
        'Vimmerse Support',
        'Customer Feedback'
      ].join(',')
    );
    const motionTypes = getMotionTypesFromBatch(batch);

    motionTypes.forEach(mt => {
      medias.forEach(media => {
        csvRows.push(
          [
            index,
            media.source,
            media.media_id,
            JSON.stringify(mt),
            nowString,
            '',
            '',
            ''
          ].join(',')
        );
        index += 1;
      });
    });

    const csvData = csvRows.join('\n');
    download(csvData);
  };

  const onDelete = () => {
    if (window.confirm('Are you sure to delete this batch?')) {
      dispatch(deleteBatch(batch_id));
      navigate('/batch');
    }
  };

  const { customer_id, story_version } = batch;

  const batch_story_url = `${mediaURL}/${customer_id}/batch/${batch_id}/story/${story_version}/${batch_id}.mp4`;

  return (
    <div className='sessions-list-page'>
      <div className='page-header'>
        <h1>Batch Detail</h1>
      </div>
      <div className='contents'>
        <Card className='mb-5'>
          <Card.Body>
            <div className='row'>
              <div className='col-md-6'>
                <div className='detail-item'>
                  <h6>Title</h6>
                  <p className=''>{batch?.title}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Created At</h6>
                  <p className=''>
                    {convertUTCtoLocal(batch.created_at).format(
                      'yyyy-MM-DD HH:mm'
                    )}
                  </p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Notified At</h6>
                  <p className=''>
                    {batch.notified_at &&
                      convertUTCtoLocal(batch.notified_at).format(
                        'yyyy-MM-DD HH:mm'
                      )}
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Email</h6>
                  <p className=''>{batch?.email}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Name</h6>
                  <p className=''>{batch?.name}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Industry</h6>
                  <p className=''>{batch?.industry}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Status</h6>
                  <p className='text-capitalize'>{batch?.status}</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='detail-item'>
                  <h6>Pose Presets</h6>
                  <p className=''>
                    {JSON.stringify(
                      batch.pose_preset
                        ? batch.pose_preset
                        : getPosePresetsFromOldBatch(batch)
                    )}
                  </p>
                </div>
              </div>
              <div className='col-md-12'>
                <div className='detail-item'>
                  <h6>Submit Params</h6>
                  <p className=''>
                    {JSON.stringify(
                      batch.submit_params
                        ? batch.submit_params
                        : DEFAULT_SUBMIT_PARAMS
                    )}
                  </p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='detail-item'>
                  <h6>Generate Type</h6>
                  <p className=''>
                    {batch.generate_type ? batch.generate_type : GENERATE_VIDEO}
                  </p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='detail-item'>
                  <h6>Is Transition?</h6>
                  <p className=''>{batch.is_transition ? 'Yes' : 'No'}</p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Uploaded</h6>
                  <p className=''>{batch?.filenames?.length}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Selected</h6>
                  <p className=''>{batch?.paid_media_ids?.length || ''}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Paid Amount($)</h6>
                  <p className=''>
                    {batch.amount_paid ? batch.amount_paid / 100 : ''}
                  </p>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className='detail-item'>
                  <h6>Batch Database URL</h6>
                  <a
                    href={`https://us-west-2.console.aws.amazon.com/dynamodbv2/home?region=us-west-2#edit-item?itemMode=2&pk=${batch.id}&route=ROUTE_ITEM_EXPLORER&sk=&table=${vimmerseBatchTable}`}
                    className='text-info'
                    target='_blank'
                  >
                    {`DynamoDB > ${vimmerseBatchTable} > ${batch.id}`}
                  </a>
                </div>
                <div className='detail-item'>
                  <h6>Batch Image Upload S3 URL</h6>
                  <a
                    href={`https://s3.console.aws.amazon.com/s3/buckets/vimmerse-distribution?region=us-west-2&prefix=vimmerse-resource/batch-uploads/${batch.id}/&showversions=false`}
                    target='_blank'
                    className='text-info'
                  >
                    {`s3://vimmerse-distribution/vimmerse-resource/batch-uploads/${batch.id}`}
                  </a>
                </div>
                <div className='detail-item'>
                  <h6>Order Page URL</h6>
                  <a href={orderLink} className='text-info' target='_blank'>
                    {orderLink}
                  </a>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='detail-item'>
                  <h6>Story</h6>
                  <ul>
                    {batch.story && batch.story.scenes
                      ? batch.story.scenes.map(scene => <li key={JSON.stringify(scene)}>{JSON.stringify(scene)}</li>)
                      : ''}
                  </ul>
                </div>
              </div>
            </div>
            <div className='row'>
              {/* <div className='col-md-6'>
                <div className='detail-item'>
                  <h6>Excel Link</h6>
                  <p className=''>
                    {batch.excel_link ? (
                      <a href={batch.excel_link} target='_blank'>
                        {batch.excel_link}
                      </a>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
              </div> */}
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Owner ID</h6>
                  <p className=''>{batch?.manager_id}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Message From User</h6>
                  <p className=''>{batch?.message}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Note to User</h6>
                  <p className=''>{batch?.note}</p>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='detail-item'>
                  <h6>Feedback</h6>
                  <p className=''>{batch?.feedback}</p>
                </div>
              </div>
            </div>
            <div className='row mt-5'>
              <div className='col-md-6'>
                <h6>Actions</h6>
                <ButtonGroup>
                  <Button variant='primary' onClick={onResubmit}>
                    {isProcessRequesting ? (
                      <i className='fa-spinner fa-solid fa-spin' />
                    ) : BATCH_STATUS_NEW === batch.status ? (
                      'Submit'
                    ) : (
                      'Resubmit'
                    )}
                  </Button>
                  <Button variant='primary' onClick={onEdit}>
                    Edit
                  </Button>
                  {/* <Button variant='primary' onClick={onGrabBatch}>
                    Grab Media
                  </Button> */}
                  <Button variant='primary' onClick={onDownloadCsvTemplate}>
                    CSV Template
                  </Button>
                  <Button variant='primary' onClick={onReady}>
                    Notify User
                  </Button>
                  <Button variant='danger' onClick={onDelete}>
                    Delete
                  </Button>
                </ButtonGroup>
              </div>
              {/* <div className='col-md-6'>
                {totalSteps > 0 && (
                  <ProgressBar className='mt-4' style={{ height: '20px' }} animated striped now={currentStep / totalSteps * 100} label={`${currentStep} of ${totalSteps}`} />
                )}
              </div> */}
            </div>
          </Card.Body>
        </Card>
        {story_version ? (
          <div className='row mb-5'>
            <div className='col-md-3'>
              <h3>Story Media</h3>
              <video controls className='w-100'>
                <source src={batch_story_url} type='video/mp4' />
              </video>
            </div>
          </div>
        ) : (
          ''
        )}
        {status === 'new' && (
          <Card>
            <Card.Body>
              <h4>Uploaded Images</h4>
              <div className='row my-3'>
                <div className='col-12'>
                  <div className='detail-item'>
                    <h6>Batch Image Upload S3 URL</h6>
                    <a
                      href={`https://s3.console.aws.amazon.com/s3/buckets/vimmerse-distribution?region=us-west-2&prefix=vimmerse-resource/batch-uploads/${batch.id}/&showversions=false`}
                      target='_blank'
                      className='text-info'
                    >
                      {`s3://vimmerse-distribution/vimmerse-resource/batch-uploads/${batch.id}`}
                    </a>
                  </div>
                </div>
              </div>
              <div className='row'>{newFileMap}</div>
            </Card.Body>
          </Card>
        )}
        <div className='mb-3'>{paginationBar()}</div>
        <div>{pageContent()}</div>
        <div className='mt-3'>{paginationBar()}</div>
        {showEditDialog && (
          <BatchDialog
            batch={batch}
            onClose={() => setShowEditDialog(false)}
            onSubmit={onUpdateBatch}
          />
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { batch } = state;
  const { allBatch } = batch;
  return {
    allBatch
  };
}

const connectedObject = connect(mapStateToProps)(BatchDetail);
export { connectedObject as BatchDetail };
