import { actionConstants } from '../constants';
import {
  successReqActionType,
  failReqActionType,
  startReqActionType
} from 'helper/requestHelper';

const initialState = {
  error: null,
  
  paginatedAssets: [],
  lastEvaluatedKeys: null,
  totalCount: 0,

  isLoadingAssetsList: false,

//   isLoadingUserMedias: false,
//   paginatedUserMedias: [],
//   paginatedUserMediasLastEvaluatedKeys: '',
};

const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case startReqActionType(actionConstants.PAGINATED_LIST_ASSETS):
      return {
        ...state,
        isLoadingAssetsList: true
      };

    case successReqActionType(actionConstants.PAGINATED_LIST_ASSETS):
      return {
        ...state,
        isLoadingAssetsList: false,
        paginatedAssets: action.payload.reset
          ? action.payload.data
          : [...state.paginatedAssets, ...action.payload.data],
        lastEvaluatedKeys: action.payload.last_evaluated_key,
        totalCount: action.payload.reset
          ? action.payload.total_count
          : state.totalCount
      };
    case failReqActionType(actionConstants.PAGINATED_LIST_ASSETS):
      return {
        ...state,
        isLoadingAssetsList: false
      };
    default:
      return state;
  }
};

export default mediaReducer;
